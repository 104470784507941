import { Component } from '@angular/core';


@Component({
  selector: 'app-nfse-emissao',
  templateUrl: './nfse-emissao.component.html',
  styleUrl: './nfse-emissao.component.scss',
})
export class NfseEmissaoComponent {

}
