import { Component } from '@angular/core';

@Component({
  selector: 'app-prestador-servico',
  templateUrl: './prestador-servico.component.html',
  styleUrl: './prestador-servico.component.scss'
})
export class PrestadorServicoComponent {

}
